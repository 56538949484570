const STAGE = process.env.REACT_APP_STAGE;

const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_COGNITO_USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;
const AWS_COGNITO_CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;

const DOMAIN_CORE = process.env.REACT_APP_DOMAIN_CORE;
const DOMAIN_WP_PNPD = process.env.REACT_APP_DOMAIN_WP_PNPD;
const DOMAIN_WP_WEST = process.env.REACT_APP_DOMAIN_WP_WEST;
const DOMAIN_PREFIX = STAGE === "prod" ? "" : STAGE + ".";
const BASE_API_URL = `https://api.${DOMAIN_PREFIX}${DOMAIN_CORE}`;
const BASE_BI_URL = `https://bi.${DOMAIN_PREFIX}${DOMAIN_CORE}`;
const BASE_GQL_URL = `https://gql.${DOMAIN_PREFIX}${DOMAIN_CORE}/graphql`;

export default {
  stage: STAGE,
  aws: {
    region: AWS_REGION,
    cognito: {
      userPoolId: AWS_COGNITO_USER_POOL_ID,
      clientId: AWS_COGNITO_CLIENT_ID,
    },
  },
  domain: {
    prefix: DOMAIN_PREFIX,
    core: DOMAIN_CORE,
    wp: {
      pnpd: DOMAIN_WP_PNPD,
      west: DOMAIN_WP_WEST,
    },
  },
  baseUrl: {
    api: BASE_API_URL,
    bi: BASE_BI_URL,
    gql: BASE_GQL_URL,
  },
  contractId: process.env.REACT_APP_CONTRACT_ID || "",
};
