/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import env from './environment.js';
console.debug('env', env);

const awsmobile = {
    "aws_project_region": env.aws.region,
    // "aws_cognito_identity_pool_id": "eu-west-2:5f39e25e-bff2-467b-97f1-7d61a732c7f2",
    "aws_cognito_region": env.aws.region,
    // "aws_user_pools_id": "eu-west-2_F2Llp6G9s",
    "aws_user_pools_id": env.aws.cognito.userPoolId,
    // "aws_user_pools_web_client_id": "5avu5epe2crn1gdedg6vuii8bv",
    "aws_user_pools_web_client_id": env.aws.cognito.clientId,
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    // "aws_appsync_graphqlEndpoint": "https://4yasobsvqnaohop5mattshqgu4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_graphqlEndpoint": env.baseUrl.gql,
    "aws_appsync_region": env.aws.region,
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        // 500_api_resolveFundPayload_core
        {
            "name": "apiFundResolvePayload",
            // "endpoint": "https://sz2jr3jvah.execute-api.eu-west-2.amazonaws.com/develop",
            "endpoint": `${env.baseUrl.api}/resolve-fund-payload`,
            "region": env.aws.region
        },
        // 502_api_confirmFundCredit_core
        {
            "name": "apiFundConfirmation",
            // "endpoint": "https://xoazi3g8of.execute-api.eu-west-2.amazonaws.com/develop",
            "endpoint": `${env.baseUrl.api}/confirm-fund-credit`,
            "region": env.aws.region
        },
        // 510_api_databaseStats_core
        {
            "name": "apiDatabaseCoreStats",
            // "endpoint": "https://5nwnrolmkg.execute-api.eu-west-2.amazonaws.com/develop",
            "endpoint": `${env.baseUrl.api}/databaseStats`,
            "region": env.aws.region
        },
        // 5.1 getTreasuryTxnStats
        // TODO: Hook up to BI Domain
        {
            "name": "apiCore",
            "endpoint": "https://qktlh7o7pk.execute-api.eu-west-2.amazonaws.com/develop/core",
            // "endpoint": `${env.baseUrl.bi}/core`,
            "region": env.aws.region
        },
        // 5.2 getTreasuryTxnSearch
        // TODO: Hook up to BI Domain
        {
            "name": "apiCoreTransactionDetails",
            "endpoint": "https://qktlh7o7pk.execute-api.eu-west-2.amazonaws.com/develop/coretransactiondetails",
            // "endpoint": `${env.baseUrl.bi}/coretransactiondetails`,
            "region": env.aws.region
        },
    ]
};


export default awsmobile;
