import { useTheme } from '@mui/material/styles';
// AWS
import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const FooterStyle = styled('footer')(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  right: theme.spacing(5),
  zIndex: 9,
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  // maxHeight: 700,
  minHeight: '92vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const isLight = theme.palette.mode === 'light';

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {/* <Logo /> */}
          <Image
            visibleByDefault
            disabledEffect
            src="/logo/logo_fdz.svg"
            alt="login"
          />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Image
              visibleByDefault
              disabledEffect={false}
              // ratio="9/21"
              ratio="4/6"
              src="/images/core_banner.jpg"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Fourdotzero Network Dashboard
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
            </Stack>

            <Authenticator
              loginMechanisms={['username']}
              // signUpAttributes={['family_name', 'given_name', 'phone_number', 'email']}
              hideSignUp={true}
            >
            </Authenticator>

            {/* <FooterStyle>
              <Image
                visibleByDefault
                disabledEffect
                src="/logo/logo_fdz.svg"
                alt="login"
              />
            </FooterStyle> */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
