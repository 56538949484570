// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function PnpLogo({ ...other }: BoxProps) {
  // const theme = useTheme();
  // const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box {...other}>
      <svg width="120" height="74" viewBox="0 0 120 74" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: 'auto', height: '56px'}}>
<g clip-path="url(#clip0_508_9956)">
<path d="M79.3179 34.7208V50.1734H56.1738V73.3333H40.757V50.1734H0.102539V0H15.5193V34.7208H40.757V0H56.1738V34.7208H79.3179Z" fill="#01070B"/>
<path d="M119.972 0V73.3333H87.0072V57.8807H104.556V15.4527H79.3179V26.9754H63.9011V0H119.972Z" fill="#01070B"/>
<path d="M79.3179 57.8807H63.9011V73.3334H79.3179V57.8807Z" fill="#ED0A72"/>
</g>
<defs>
<clipPath id="clip0_508_9956">
<rect width="120" height="74" fill="white"/>
</clipPath>
</defs>
</svg>

    </Box>
  );
}
